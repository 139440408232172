export enum StringList {
  MAIN_TITLE = 'Semantic Switch',
  MAIN_SUBTITLE = 'Network Software for Semantic Ontology',
  HOME_COPY1 = 'An application service providing knowledge management.',
  HOME_COPY2 = 'Data-driven maps of business entity relationships.',
  FOOTER_COPYRIGHT = 'WL Solutions LLC. All Rights Reserved.',
  FOOTER_TRADEMARK = 'Semantic Switch is a registered trademark.',
  MOBILE_FOOTER_COPYRIGHT_ONE = '©2024 WL Solutions LLC.',
  MOBILE_FOOTER_COPYRIGHT_TWO = 'All Rights Reserved.',
  MOBILE_FOOTER_TRADEMARK = 'Smart Narrative is a registered trademark.',
  NETWORK_IMAGE = 'https://smartnarrative.network/images/network.jpg',
  HOME_LINK = '/',
  ONTOLOGY_LINK = '/ontology',
  SWITCH_LINK = '/switch',
  PRICING_LINK = '/pricing',
  ONTOLOGY_LABEL = 'ONTOLOGY',
  ONTOLOGY_1 = 'An ontology represents a set of concepts, and the relationships between those concepts. It specifies the types of things that can exist, and the relationships between those things.',
  ONTOLOGY_2 = 'Semantic ontology is concerned with the meaning of concepts, and how these meanings can be used for enterprise knowledge management.',
  SWITCH_1 = 'A semantic switch creates a network of entities related by organizational concepts.',
  SWITCH_2 = 'The relationships are mapped by data flowing from the entities.',
  SWITCH_LABEL = 'SWITCH',
  PRICING_LABEL = 'SERVICES',
  PRICING_PRODUCT_LABEL_1 = 'STARTER',
  PRICING_PRODUCT_LABEL_2 = 'ENTERPRISE',
  PRICING_PRODUCT_LABEL_3 = 'CUSTOM',
  PRICING_PRODUCT_COPY_1 = 'Basic entity map with standard data categories and one level of organizational relationships.',
  PRICING_PRODUCT_COPY_2 = 'Extended entity map with multi-level data categories and organizational relationships.',
  PRICING_PRODUCT_COPY_3 = 'Custom entity map with data categories and organizational relationships created by AI.',
  PRICING_PRODUCT_PRICE_1 = '$9.99/month',
  PRICING_PRODUCT_PRICE_2 = '$25/month',
  PRICING_PRODUCT_PRICE_3 = '$100/month',
  PRICING_COPY_1 = 'Build a semantic switch to provide knowledge management services.',
  PRICING_COPY_2 = 'Choose from one of three options: ',
}
